import * as React from 'react'
import styled from 'styled-components'

export interface AddressProps {
  name?: string
  street?: string
  postcode?: string
  housenumber?: string
  city?: string
}

const Name = styled.span`
  font-weight: bold;
`

export const Address: React.FC<AddressProps> = ({ name, street, postcode, housenumber, city }) => {
  return (
    <address>
      <Name>{name}</Name>
      <br />
      {street} {housenumber}
      <br />
      {postcode} {city}
    </address>
  )
}
