import { navigate } from 'gatsby'
import * as React from 'react'
import { Nav, Navbar } from 'react-bootstrap'

export const Navigation: React.FC = () => {
  const goto = (uri: string) => () => navigate(uri)

  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand onClick={goto('/')}>Hyper Kurt Boilerplate</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link onClick={goto('/')}>Welcome</Nav.Link>
          <Nav.Link onClick={goto('/team')}>Team</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
