import * as React from 'react'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import { faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons'
import { Icon } from '../Icon'

export interface FooterProps {
  children?: React.ReactNode
}

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  border-top: 1px solid var(--gray);
  background-color: white;

  text-align: center;
  color: var(--discreet);

  padding: var(--gutter) 0;
`

const ListInline = styled(Container)`
  display: flex;
  list-style: none;

  > li {
    margin-right: var(--gutter);
    display: flex;
  }
`

export const Footer: React.FC<FooterProps> = () => {
  return (
    <Wrapper>
      <Container>
        <ListInline>
          <li>
            <Icon icon={faPhone} prepend={true} /> {'settings.phone'}
          </li>
          <li>
            <Icon icon={faEnvelope} prepend={true} />
            {'settings.email'}
          </li>
        </ListInline>
      </Container>
    </Wrapper>
  )
}
