import * as React from 'react'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import { Navigation } from './Navigation'
import { Footer } from './Footer'

export interface LayoutProps {
  children?: React.ReactNode
}

const StyledContainer = styled(Container)`
  margin-top: 2em;
`

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Navigation />
      <StyledContainer>{children}</StyledContainer>
      <Footer />
    </>
  )
}
