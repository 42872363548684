import { StaticQuery, graphql } from 'gatsby'
import * as React from 'react'
import { Helmet } from 'react-helmet'

export interface MetaProps {
  title?: string
  description?: string
}

export const Meta: React.FC<MetaProps> = ({ title, description }) => {
  const toTitle = (data: GatsbyTypes.MetaQueryQuery) => [title, data.site?.meta?.title].join(' | ')

  return (
    <StaticQuery<GatsbyTypes.MetaQueryQuery>
      query={graphql`
        query MetaQuery {
          site {
            meta: siteMetadata {
              title
              description
              siteUrl
            }
          }
        }
      `}
      render={data => (
        <Helmet
          title={toTitle(data)}
          htmlAttributes={{
            lang: 'de'
          }}
          meta={[
            { name: 'twitter:card', content: 'summary' },
            { property: 'og:title', content: title || data.site?.meta?.title },
            { property: 'og:type', content: 'website' },
            {
              property: 'og:description',
              content: description || data.site?.meta?.description
            }
          ]}
        />
      )}
    />
  )
}
